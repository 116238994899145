<template>
  <div class="score">
    <div class="score_box">
      <img class="score_img" src="@/assets/images/score.png" alt="" />
      <div class="close" @click="handelClose()">
        <img src="@/assets/images/close.png" alt="" />
      </div>
      <div class="text_box">
        <p>本次测评评分</p>
        <div class="xx_box">
          <div
            v-for="(i, index) in list"
            :key="index"
            @click="clickStars(index)"
          >
            <img :src="xing > index ? stara : starb" />
          </div>
        </div>
        <p class="status">{{ rateScoreText }}</p>
        <div class="btn" @click="handelSub()">提交</div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { getSubScore } from "@/request/api";
import { Toast } from "vant";
export default {
  data() {
    return {
      rateScoreText: "",
      rateScoreDesc: ["不满意", "无所谓", "一般", "比较有帮助", "非常满意"],
      list: [0, 1, 2, 3, 4],
      stara: require("@/assets/images/xx_active.png"),
      starb: require("@/assets/images/xx.png"),
      xing: 0,
    };
  },
  components: {},
  methods: {
    clickStars(i) {
      this.rateScoreText = this.rateScoreDesc[i];
      this.xing = i + 1;
      console.log(this.xing);
    },

    handelSub() {
      this.getSubScore(this.code, this.xing);
      this.handelClose();
    },

    handelClose() {
      this.$emit("handelClose", false);
    },

    getSubScore(code, xing) {
      let param = {
        code: code,
        score: xing,
      };
      getSubScore(param).then((res) => {
        console.log(res);
        Toast("提交成功!");
        this.$emit('getEvaluationReport')
      });
    },
  },
  props: {
    code: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
};
</script>

<style scoped lang="less">
.score {
  .score_box {
    width: 5.4rem;

    .score_img {
      width: 100%;
      height: 3.64rem;
    }

    .close {
      width: 0.41rem;
      height: 0.41rem;
      position: absolute;
      right: 0;
      top: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .text_box {
      text-align: center;
      overflow: hidden;
      padding-bottom: 0.6rem;
      background-color: #ffffff;

      .xx_box {
        display: flex;
        align-items: center;
        margin: 0.4rem 0 0.2rem 0;
        justify-content: center;

        div {
          width: 0.42rem;
          height: 0.42rem;
          margin-right: 0.25rem;

          &:last-child {
            margin: 0;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      p {
        font-size: 0.36rem;
        font-family: "PingFangSC-Regular";
        font-weight: 500;
        color: #000000;
        line-height: 0.38rem;
        margin-top: 0.39rem;
      }

      .status {
        height: 0.3rem;
        font-size: 0.28rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #666666;
        margin: 0;
      }

      .btn {
        width: 2rem;
        height: 0.7rem;
        background: #0067b0;
        border-radius: 0.35rem;
        text-align: center;
        line-height: 0.7rem;
        font-size: 0.3rem;
        font-family: "PingFangSC-Regular";
        font-weight: 500;
        color: #ffffff;
        margin: 0.7rem auto 0;
      }
    }
  }
}
</style>
